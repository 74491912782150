import { useMemo } from 'react';
import { ApolloError } from 'apollo-client';

import { Product } from 'src/utils/helpers/product';
import { SponsoredBrandQuery } from 'src/utils/ads/types';
import { getMostCommonBrandId } from 'src/utils/ads/helpers';
import useViewportVisibility, { ViewportVisibility } from 'src/hooks/use-viewport-visibility';

import { useSponsoredBrandData, useSponsoredBrandHandlers } from 'src/utils/ads/hooks/sponsored-brand';

type UseSponsoredBrandProductsParams = {
  sponsoredBrandQuery: SponsoredBrandQuery;
};

export type UseSponsoredBrandProductsReturn = {
  brandName: string | undefined;
  error: ApolloError | undefined;
  handleProductClick: (product: Product, index: number) => void;
  handleProductImpression: (product: Product) => void;
  handleQuickAddToCart: (product: Product, index: number) => void;
  handleViewAllClick: () => void;
  loading: boolean;
  mostCommonBrandId: string;
  products: Product[];
  viewportVisibility: ViewportVisibility;
};

export function useSponsoredBrandProducts({
  sponsoredBrandQuery,
}: UseSponsoredBrandProductsParams): UseSponsoredBrandProductsReturn {
  const viewportVisibility = useViewportVisibility();
  const { data, loading, error } = sponsoredBrandQuery;
  const { brandName, campaignId, globalTrackers, products } = useSponsoredBrandData(data);
  const { hasBeenVisible } = viewportVisibility;

  const {
    handleProductClick,
    handleProductImpression,
    handleViewAllClick,
    handleQuickAddToCart,
  } = useSponsoredBrandHandlers({
    brandName,
    campaignId,
    globalTrackers,
    products,
    hasBeenVisible,
    placement: 'homepage-sponsored-brand-carousel',
  });

  const mostCommonBrandId = useMemo(() => getMostCommonBrandId(products), [products]);

  return {
    brandName,
    products,
    loading,
    error,
    handleProductClick,
    handleQuickAddToCart,
    handleViewAllClick,
    handleProductImpression,
    viewportVisibility,
    mostCommonBrandId,
  };
}
