import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ApolloError } from 'apollo-client';
import { useRouter } from 'next/router';

import { RecommendedProductsQuery } from 'src/personalization/data-layer/types';
import { Product, parseProduct } from 'src/utils/helpers/product';

import { tracker } from 'src/utils/analytics';
import useViewportVisibility, { ViewportVisibility } from 'src/hooks/use-viewport-visibility';

import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';

import { useQuickAddToCart } from 'src/components/product-carousel/use-quick-add-to-cart';

type UseBuyItAgainProductsParams = {
  recommendedProductsQuery: RecommendedProductsQuery;
};

export type UseBuyItAgainProductsReturn = {
  error?: ApolloError;
  loading: boolean;
  products: Product[];
  handleProductClick: (product: Product, index: number) => void;
  handleQuickAddToCart: (product: Product, index: number) => void;
  handleViewAllClick: (destinationUrl: string) => void;
  viewportVisibility: ViewportVisibility;
};

export function useBuyItAgainProducts({
  recommendedProductsQuery,
}: UseBuyItAgainProductsParams): UseBuyItAgainProductsReturn {
  const router = useRouter();
  const impressionTriggered = useRef(false);
  const { handleAddToCart } = useQuickAddToCart();
  const viewportVisibility = useViewportVisibility();

  const { data, loading, error } = recommendedProductsQuery;
  const { hasBeenVisible } = viewportVisibility;

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: ProductRecommendationSection.buyItAgain,
  });

  const normalizedProducts = useMemo(
    () => data?.getRecommendedProducts.map((item): Product => parseProduct(item)) ?? [],
    [data?.getRecommendedProducts]
  );

  const shouldTriggerImpression = normalizedProducts.length && hasBeenVisible && !impressionTriggered.current;

  const handleImpression = useCallback(() => {
    tracker.buyItAgainImpression({ products: normalizedProducts, trackerSource });
  }, [normalizedProducts, trackerSource]);

  useEffect(() => {
    if (shouldTriggerImpression) {
      handleImpression();
      impressionTriggered.current = true;
    }
  }, [handleImpression, shouldTriggerImpression]);

  const handleProductClick = useCallback(
    (product, index) => {
      tracker.productClicked({ product, position: index, trackerSource });
    },
    [trackerSource]
  );

  const handleQuickAddToCart = useCallback(
    (product, index): void => {
      tracker.setContext({
        activeProductPosition: index,
      });
      void handleAddToCart(product, trackerSource);
    },
    [handleAddToCart, trackerSource]
  );

  const handleViewAllClick = useCallback(
    (destinationUrl): void => {
      tracker.buyItAgainViewAllClicked({ destinationUrl, trackerSource });
    },
    [trackerSource]
  );

  return {
    products: normalizedProducts,
    loading,
    error,
    handleProductClick,
    handleQuickAddToCart,
    handleViewAllClick,
    viewportVisibility,
  };
}
