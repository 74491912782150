import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { ApolloError } from 'apollo-client';
import { useTheme } from 'styled-components';

import type { Product } from 'src/utils/helpers/product';
import { PersonalizedProductsQuery } from 'src/personalization/data-layer/types';
import { SponsoredBrandQuery } from 'src/utils/ads/types';
import useViewportVisibility, { ViewportVisibility } from 'src/hooks/use-viewport-visibility';

import { ItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';

import { useCardDimensions } from 'src/components/product-card/product-card.utils';
import type { Theme } from './types';

import { useSponsoredBrandProducts } from './use-sponsored-brand-products';
import { usePersonalizedProducts } from './use-personalized-products';

type UsePersonalizedProductsCarouselParams = {
  maxProducts?: number;
  maxSponsoredProducts?: number;
  personalizedProductsQuery: PersonalizedProductsQuery;
  sponsoredBrandQuery?: SponsoredBrandQuery;
};

type UsePersonalizedProductsCarouselReturn = {
  dimensions: ReturnType<typeof useCardDimensions>;
  error?: ApolloError;
  loading: boolean;
  products: Product[];
  theme: Theme;
  handleProductClick: (product: Product, index: number, trackerSourceOverride?: ItemListName) => void;
  handleProductImpression: (product: Product) => void;
  handleQuickAddToCart: (product: Product, index: number, trackerSourceOverride?: ItemListName) => void;
  viewportVisibility: ViewportVisibility;
};

export const usePersonalizedProductsCarousel = ({
  maxProducts = 20,
  maxSponsoredProducts = 3,
  personalizedProductsQuery,
  sponsoredBrandQuery,
}: UsePersonalizedProductsCarouselParams): UsePersonalizedProductsCarouselReturn => {
  const viewportVisibility = useViewportVisibility();

  const {
    products: sponsoredBrandProducts,
    loading: sponsoredBrandLoading,
    error: sponsoredBrandError,
    handleProductClick: sponsoredProductClick,
    handleProductImpression: sponsoredProductImpression,
    handleQuickAddToCart: sponsoredProductAddToCart,
  } = useSponsoredBrandProducts({
    sponsoredBrandQuery,
    viewportVisibility,
  });

  const {
    products: personalizedProducts,
    loading: personalizedProductLoading,
    error: personalizedProductError,
    handleProductClick: personalizedProductClick,
    handleQuickAddToCart: personalizedProductAddToCart,
  } = usePersonalizedProducts({
    personalizedProductsQuery,
  });

  const dimensions = useCardDimensions({ gutter: 12 });
  const { customized } = useTheme();
  const theme = {
    backgroundColor: '#f5f6f7',
    primaryColor: customized.colors.buttonsLinks,
  };

  const products = useMemo(() => {
    if (sponsoredBrandLoading || personalizedProductLoading) {
      return [];
    }

    if (sponsoredBrandError) {
      return personalizedProducts.slice(0, maxProducts);
    }

    return _.uniqBy(sponsoredBrandProducts.slice(0, maxSponsoredProducts).concat(personalizedProducts), 'id').slice(
      0,
      maxProducts
    );
  }, [
    sponsoredBrandLoading,
    personalizedProductLoading,
    sponsoredBrandError,
    sponsoredBrandProducts,
    personalizedProducts,
    maxProducts,
    maxSponsoredProducts,
  ]);

  const handleProductClick = useCallback(
    (product, index, trackerSourceOverride) => {
      if (product.adTrackers) {
        void sponsoredProductClick?.(product, index);
      } else {
        void personalizedProductClick(product, index, trackerSourceOverride);
      }
    },
    [personalizedProductClick, sponsoredProductClick]
  );

  const handleQuickAddToCart = useCallback(
    (product, index, trackerSourceOverride) => {
      if (product.adTrackers) {
        void sponsoredProductAddToCart?.(product, index);
      } else {
        void personalizedProductAddToCart(product, index, trackerSourceOverride);
      }
    },
    [personalizedProductAddToCart, sponsoredProductAddToCart]
  );

  const handleProductImpression = useCallback(
    (product) => {
      if (product.adTrackers) {
        void sponsoredProductImpression?.(product);
      }
    },
    [sponsoredProductImpression]
  );

  return {
    products,
    loading: sponsoredBrandLoading || personalizedProductLoading,
    error: personalizedProductError, // Don't block on sponsoredBrandError
    theme,
    dimensions,
    handleProductClick,
    handleQuickAddToCart,
    handleProductImpression,
    viewportVisibility,
  };
};
