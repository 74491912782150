import React from 'react';

type SvgShoppingBagsProps = {
  width?: number;
  height?: number;
};

const ShoppingBags = ({ width = 52, height = 52 }: SvgShoppingBagsProps): JSX.Element => (
  <svg width={width} height={height} viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.3467 8.32C9.86807 8.32 9.41628 8.5528 9.12245 8.95083L4.15991 14.6331V39.9606C4.15991 42.6679 6.24303 44.8626 8.81268 44.8626H32.0765C34.6462 44.8626 36.7293 42.6679 36.7293 39.9606V14.6331L31.7667 8.95083C31.4729 8.5528 31.0211 8.32 30.5425 8.32H10.3467ZM10.7772 10.6403H30.112L33.6274 14.6658H7.26176L10.7772 10.6403ZM20.4446 28.5222C24.2657 28.5459 27.3888 25.5086 27.4237 21.7319C27.4237 20.9687 27.0791 20.3522 26.3071 20.3522C25.5372 20.3522 25.4696 21.2033 25.4696 21.7139C25.4696 23.7564 23.4107 26.7522 20.4446 26.7522C17.4785 26.7522 15.4196 23.7564 15.4196 21.7139C15.4196 21.3309 15.2124 20.3522 14.4425 20.3522C13.6705 20.3522 13.4654 20.9687 13.4654 21.7319C13.5003 25.5086 16.6235 28.5459 20.4446 28.5222Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.4287 8.4189C9.96013 8.4189 9.51779 8.6517 9.2301 9.04973L4.37134 14.6332V39.9606C4.37134 42.6679 6.41089 44.8627 8.92681 44.8627H31.7042C34.2201 44.8627 36.2596 42.6679 36.2596 39.9606V14.6332L31.4009 9.04973C31.1132 8.6517 30.6708 8.4189 30.2022 8.4189H10.4287ZM34.0581 17.0842H6.57345V38.3266C6.64907 40.7776 7.40832 42.4116 9.61043 42.4116H31.0211C33.2226 42.4116 33.9819 40.7776 34.0581 38.3266V17.0842ZM20.3155 28.5223C24.0567 28.546 27.1145 25.5086 27.1487 21.7319C27.1487 20.9688 26.8113 20.3523 26.0554 20.3523C25.3016 20.3523 25.2354 21.2033 25.2354 21.7139C25.2354 23.7565 23.2196 26.3705 20.3155 26.3705C17.4114 26.3705 15.3956 23.7565 15.3956 21.7139C15.3956 21.331 15.1927 20.3523 14.4389 20.3523C13.683 20.3523 13.4823 20.9688 13.4823 21.7319C13.5164 25.5086 16.5743 28.546 20.3155 28.5223Z'
      fill='#ECF0F3'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.3467 8.4189C9.86807 8.4189 9.41628 8.6517 9.12245 9.04973L4.15991 14.6332V39.9606C4.15991 42.6679 6.24303 44.8627 8.81268 44.8627H32.0765C34.6462 44.8627 36.7293 42.6679 36.7293 39.9606V14.6332L31.7667 9.04973C31.4729 8.6517 31.0211 8.4189 30.5425 8.4189H10.3467ZM34.4807 17.0842H6.40905V38.3265C6.48629 40.7776 7.26176 42.4116 9.5109 42.4116H31.3789C33.6274 42.4116 34.4029 40.7776 34.4807 38.3265V17.0842ZM10.7772 10.7392H30.112L33.6274 14.6658H7.26176L10.7772 10.7392ZM20.4446 28.5222C24.2657 28.546 27.3888 25.5086 27.4237 21.7319C27.4237 20.9688 27.0791 20.3522 26.3071 20.3522C25.5372 20.3522 25.4696 21.2033 25.4696 21.7139C25.4696 23.7565 23.4107 26.3705 20.4446 26.3705C17.4785 26.3705 15.4196 23.7565 15.4196 21.7139C15.4196 21.331 15.2124 20.3522 14.4425 20.3522C13.6705 20.3522 13.4654 20.9688 13.4654 21.7319C13.5003 25.5086 16.6235 28.546 20.4446 28.5222Z'
      fill='#646D72'
    />
    <path d='M25.4893 26.6846L28.5365 23.6763L30.568 22.6395H32.5995L30.568 42.4107H25.4893V26.6846Z' fill='#ECF0F3' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.3426 22.6395C31.047 22.6395 30.7681 22.7842 30.5866 23.0316L27.5222 26.0714V41.8155C27.5222 43.4985 28.8086 44.8628 30.3953 44.8628H44.7609C46.3477 44.8628 47.634 43.4985 47.634 41.8155V26.0714L44.5696 23.0316C44.3882 22.7842 44.1092 22.6395 43.8136 22.6395H31.3426ZM31.6084 22.9429H43.5478L45.7186 26.0917H29.4376L31.6084 22.9429ZM37.5781 34.7052C39.9377 34.7199 41.8662 32.8319 41.8878 30.4842C41.8878 30.0098 41.675 29.6266 41.1982 29.6266C40.7228 29.6266 40.6811 30.1556 40.6811 30.473C40.6811 31.7427 39.4097 33.6049 37.5781 33.6049C35.7465 33.6049 34.4752 31.7427 34.4752 30.473C34.4752 30.2349 34.3472 29.6266 33.8718 29.6266C33.395 29.6266 33.2684 30.0098 33.2684 30.4842C33.29 32.8319 35.2186 34.7199 37.5781 34.7052Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.3426 22.6395C31.047 22.6395 30.7681 22.7842 30.5866 23.0316L27.5222 26.0714V41.8155C27.5222 43.4985 28.8086 44.8628 30.3953 44.8628H44.7609C46.3477 44.8628 47.634 43.4985 47.634 41.8155V26.0714L44.5696 23.0316C44.3882 22.7842 44.1092 22.6395 43.8136 22.6395H31.3426ZM46.2455 27.5951H28.9111V40.7998C28.9588 42.3234 29.4376 43.3391 30.8265 43.3391H44.3301C45.7186 43.3391 46.1975 42.3234 46.2455 40.7998V27.5951ZM37.5781 34.7052C39.9377 34.7199 41.8662 32.8319 41.8878 30.4842C41.8878 30.0098 41.675 29.6266 41.1982 29.6266C40.7228 29.6266 40.6811 30.1556 40.6811 30.473C40.6811 31.7427 39.4097 33.3677 37.5781 33.3677C35.7465 33.3677 34.4752 31.7427 34.4752 30.473C34.4752 30.2349 34.3472 29.6266 33.8718 29.6266C33.395 29.6266 33.2684 30.0098 33.2684 30.4842C33.29 32.8319 35.2186 34.7199 37.5781 34.7052Z'
      fill='#ECF0F3'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.3426 22.6395C31.047 22.6395 30.7681 22.7842 30.5866 23.0316L27.5222 26.0714V41.8155C27.5222 43.4985 28.8086 44.8628 30.3953 44.8628H44.7609C46.3477 44.8628 47.634 43.4985 47.634 41.8155V26.0714L44.5696 23.0316C44.3882 22.7842 44.1092 22.6395 43.8136 22.6395H31.3426ZM45.8057 27.5951H29.3506V40.7998C29.3983 42.3234 29.6885 42.8313 31.0773 42.8313H44.282C45.6705 42.8313 45.7576 42.3234 45.8057 40.7998V27.5951ZM31.8899 24.1631H43.2663L44.7899 25.5636H30.0616L31.8899 24.1631ZM37.6797 34.7052C40.0392 34.7199 41.8662 32.8319 41.8878 30.4842C41.8878 30.0098 41.675 29.6266 41.1982 29.6266C40.7228 29.6266 40.4095 30.1556 40.4095 30.473C40.4095 31.7427 39.8128 33.1817 37.6797 33.1817C35.4641 33.1817 34.6324 31.7427 34.6324 30.473C34.6324 30.2349 34.3472 29.6266 33.8718 29.6266C33.395 29.6266 33.2684 30.0098 33.2684 30.4842C33.29 32.8319 35.3201 34.7199 37.6797 34.7052Z'
      fill='#646D72'
    />
  </svg>
);

export default ShoppingBags;
