import React, { memo, useState, useEffect } from 'react';
import { space, color } from 'styled-system';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { useViewport } from 'src/contexts/viewport';
import useTranslation from 'hooks/use-translation';

import { DesktopOnly, MobileOnly } from 'shared/components';
import Button from 'components/core/button';
import { heading2, heading3 } from 'components/core/typography';
import CategoryTile from 'components/category-tile';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

const CategoryTilesGrid = function CategoryGrid({ categories = [], hideHeader = false, collapsible = true, ...props }) {
  const { t } = useTranslation();
  const { width } = useViewport();
  const { dispensary } = useDispensary();
  const router = useRouter();

  const initialExpandedValue = !collapsible;
  const [expanded, setExpanded] = useState(initialExpandedValue);
  useEffect(() => setExpanded(initialExpandedValue), [initialExpandedValue, router.asPath]);

  const firstSix = _.take(categories, 6);
  const showViewMoreButton = collapsible && !expanded && categories?.length > 6;

  return (
    <Container {...props}>
      <DesktopOnly mediaQuery='sm'>
        <Grid>
          {!hideHeader && <Heading>{t('common.category_plural', 'Categories')}</Heading>}
          {_.map(categories, (category) => (
            <StyledCategoryTile key={category.key} category={category} dispensary={dispensary} height='187px' />
          ))}
        </Grid>
      </DesktopOnly>
      <MobileOnly mediaQuery='xs'>
        <Grid>
          {!hideHeader && <Heading>{t('common.category_plural', 'Categories')}</Heading>}
          {_.map(expanded ? categories : firstSix, (category) => (
            <StyledCategoryTile
              key={category.key}
              category={category}
              dispensary={dispensary}
              height={width * 0.435 - 41}
            />
          ))}
          {showViewMoreButton && (
            <ViewMoreButton onClick={() => setExpanded(true)}>{t('common.view_all', 'View All')}</ViewMoreButton>
          )}
        </Grid>
      </MobileOnly>
    </Container>
  );
};

export default memo(CategoryTilesGrid);

const Container = styled.div`
  ${({ isMarketplace, theme: { breakpoints, colors } }) => css`
    background-color: ${colors.grey[95]};

    ${breakpoints.down('sm')} {
      background-color: ${isMarketplace ? null : colors.white};
      ${color}
    }

    ${color}
    ${space}
  `}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 214px);
  grid-auto-rows: max-content;
  gap: 12px;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Heading = styled.h2`
  ${heading2};
  margin-bottom: 5px;
  grid-column: 1 / -1;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${heading3}
  }
`;

const StyledCategoryTile = styled(CategoryTile)`
  border: 1px solid #e3e7e9;
  height: 187px;
  width: 214px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    height: ${({ height }) => `${height}px`};
  }
`;

const ViewMoreButton = styled(Button)`
  background: ${({ theme }) => theme.colors.blueGrey[95]};
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 16.5px;
  text-transform: capitalize;
  border-radius: ${({ theme }) => theme.customized.radius.buttons};
  height: 50px;
  grid-column: 1 / -1;
  margin-top: 14.5px;
  cursor: pointer;
`;
