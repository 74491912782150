import styled from 'styled-components';

import { Typography } from 'src/typography';
import { Button } from 'src/components/core';
import { CarouselContainer } from 'src/components/hybrid-carousel/hybrid-carousel.styles';

export const Wrapper = styled(CarouselContainer)`
  margin: ${({ placement }) => (placement === 'secondary' ? '10px auto' : '40px auto')};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  color: #2c3236;
  padding: 20px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 24px;
    max-width: 340px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 40px 0 40px 40px;
    max-width: 500px;
  }
`;

export const Heading = styled(Typography.Heading)`
  margin-bottom: 10px;
`;

export const Subheading = styled(Typography.Title)`
  margin-bottom: 32px;
`;

export const AlreadyHaveAccount = styled(Typography.Body)`
  margin-top: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  max-width: 358px;
`;

export const SignUpButton = styled(Button)`
  box-shadow: 0px 2px 20px 0px ${({ primaryColor }) => `${String(primaryColor)}40`};
`;

export const LoginButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  cursor: pointer;
  font-weight: 600;
  padding: 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export const Products = styled.div`
  overflow: hidden;
  position: relative;
`;

export const ProductsViewport = styled.div`
  overflow: auto;
  padding: 10px 0 20px 20px;
  width: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1320px) {
    overflow: hidden;
  }
`;

export const ProductsWrapper = styled.div`
  display: flex;

  // End Watcher
  // Needs to subtract 15px to account for the padding-right on the last card
  & > :last-child {
    padding-right: 5px;
  }

  button[class^='circle-button'] {
    top: 10px;
  }
`;

const Gradient = styled.div`
  display: block;
  height: 100%;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.1s;
  width: 32px;
  z-index: 10;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 64px;
  }

  @media (min-width: 1280px) {
    display: none;
  }
`;

export const GradientLeft = styled(Gradient)`
  background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--fade-to-color) 100%);
  left: 0;
`;

export const GradientRight = styled(Gradient)`
  background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--fade-to-color) 100%);
  right: 0;
`;
