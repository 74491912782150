import styled from 'styled-components';

import { focusStyles } from 'src/theme';

import { Details } from 'src/components/product-card/product-card.components';
import { StrainDetails } from 'src/components/product-card/strain-details';
import { QuickAddButton } from 'src/components/product-card/quick-add-button';

const textTruncationStyles = `
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Item = styled.div`
  margin: ${({ dimensions }) => `0 0 ${String(dimensions.gutter)}px 0`};
  padding: ${({ dimensions }) => `${String(dimensions.padding)}px 0`};

  /* The last child of the container is the IO watcher div, so we need to target the last Item */
  &:has(+ :not(&)) {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .focus-visible {
    ${focusStyles}
  }
`;

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const BrandStrain = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Brand = styled.span`
  ${textTruncationStyles}
  color: ${({ theme }) => theme.colors.primaryBlack};
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  margin: 1px 10px 0 0;
`;

export const StyledDetails = styled(Details)`
  padding: 0 16px;
  max-width: 295px;
`;

export const StyledStrainDetails = styled(StrainDetails)`
  ${textTruncationStyles}
`;

export const StyledQuickAddButton = styled(QuickAddButton)`
  flex-shrink: 0;
  position: static;
`;
