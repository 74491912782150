import { ApolloError } from 'apollo-client';

import type { Product } from 'src/utils/helpers/product';
import { SponsoredBrandQuery } from 'src/utils/ads/types';

import usePaths from 'src/hooks/use-paths';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { ViewportVisibility } from 'src/hooks/use-viewport-visibility';

import { ItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';

import { useCardDimensions } from 'src/components/product-card/product-card.utils';
import { useCarouselTheme } from '../../carousels.utils';

import { useSponsoredBrandProducts } from './use-sponsored-brand-products';

type CarouselTheme = {
  backgroundColor: string;
  loadingColor: string;
  primaryColor: string;
  primaryColorHover: string;
  textColor?: string;
};

type UseSponsoredBrandCarouselParams = {
  sponsoredBrandQuery: SponsoredBrandQuery;
};

type UseSponsoredBrandCarouselReturn = {
  brandHref: string;
  brandName: string | undefined;
  dimensions: ReturnType<typeof useCardDimensions>;
  error?: ApolloError;
  loading: boolean;
  products: Product[];
  theme: CarouselTheme;
  viewportVisibility: ViewportVisibility;
  handleProductClick: (product: Product, index: number, trackerSourceOverride?: ItemListName) => void;
  handleProductImpression: (product: Product) => void;
  handleQuickAddToCart: (product: Product, index: number, trackerSourceOverride?: ItemListName) => void;
  handleViewAllClick: () => void;
};

export const useSponsoredBrandCarousel = ({
  sponsoredBrandQuery,
}: UseSponsoredBrandCarouselParams): UseSponsoredBrandCarouselReturn => {
  const { dispensary } = useDispensary();
  const theme = useCarouselTheme(dispensary);
  const { href } = usePaths({ brands: true });
  const dimensions = useCardDimensions({ gutter: 12 });

  const {
    brandName,
    products,
    loading,
    error,
    handleProductClick,
    handleProductImpression,
    handleQuickAddToCart,
    handleViewAllClick,
    mostCommonBrandId,
    viewportVisibility,
  } = useSponsoredBrandProducts({
    sponsoredBrandQuery,
  });

  const brandHref = `${href}/${mostCommonBrandId}`;

  return {
    brandName,
    brandHref,
    products,
    loading,
    error,
    theme,
    dimensions,
    handleProductClick,
    handleQuickAddToCart,
    handleProductImpression,
    handleViewAllClick,
    viewportVisibility,
  };
};
