import styled from 'styled-components';

import { TabContext, TabPanel } from '@material-ui/lab';

import { Tabs, Tab } from 'components/core';
import { CarouselContainer } from 'src/components/hybrid-carousel/hybrid-carousel.styles';

export const StyledCarouselContainer = styled(CarouselContainer)`
  margin: ${({ placement }) => (placement === 'secondary' ? '10px auto' : '40px auto')};

  button[class^='circle-button'] {
    top: 10px;
  }
`;

export const StyledTabContext = styled(TabContext)`
  padding-top: 20px;
`;

export const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    padding: 20px 20px 0;
    border-bottom: 0;
    width: 100%;
  }

  & .MuiTabs-indicator {
    display: none;
  }
`;

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    flex-grow: 1;
    background-color: #e3e7e9;
    border-radius: 100px;
    color: #2c3236;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    max-width: 162px;
    margin: 0 0 0 10px;
    padding: 8px;
    text-align: center;
    transition: background-color 0.2s ease;

    &:first-child {
      margin-left: 0;
    }
  }

  &.MuiTab-root.Mui-selected {
    background-color: #2c3236;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0;
    width: 100%;
  }
`;
