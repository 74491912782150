import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'components/core';

import { useIntersectionObserver } from 'src/hooks/use-intersection-observer';
import { useCardDimensions } from 'src/components/product-card/product-card.utils';

import usePaths from 'hooks/use-paths';
import useUI from 'shared/hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

import ShoppingBags from 'src/assets/shopping-bags-icon';

import {
  OuterContainer,
  InnerContainer,
  ListHeader,
  ListHeaderText,
  ListHeaderLink,
  ListContainer,
  ProductList,
  GradientEnd,
  GradientStart,
  ListPlaceholder,
} from './buy-it-again-list.styles';

type BuyItAgainListProps = {
  backgroundColor?: string;
  children: React.ReactNode;
  dimensions: ReturnType<typeof useCardDimensions>;
  productCount: number;
  handleViewAllClick: (destinationUrl: string) => void;
};

const listPadding = 20;
export const BuyItAgainList = React.forwardRef(
  (
    { backgroundColor, children, dimensions, productCount = 0, handleViewAllClick }: BuyItAgainListProps,
    ref
  ): JSX.Element | null => {
    const UI = useUI();
    const { dispensary } = useDispensary();
    const { href } = usePaths({ dispensary });
    const [productListMaxHeight, setProductListMaxHeight] = useState<number>(300);

    const listRef = useRef<HTMLDivElement>(null);
    const startWatcherRef = useRef<HTMLDivElement>(null);
    const startWatcher = useIntersectionObserver(startWatcherRef);
    const endWatcherRef = useRef<HTMLDivElement>(null);
    const endWatcher = useIntersectionObserver(endWatcherRef);

    const { desktop, gutter, padding } = dimensions;
    const showPlaceholder = productCount < 2;
    const listWidth = desktop * 2 + padding * 4 + gutter;

    const hasUserPaths = UI.isEmbedded || UI.isStoreFront;
    const accountOrderHistoryPath = hasUserPaths ? `${href}/user/orders` : '/account/orders';

    useEffect(() => {
      if (listRef.current && !showPlaceholder) {
        setProductListMaxHeight(listRef.current.clientHeight - 62);
      }
    }, [showPlaceholder]);

    return (
      <OuterContainer gutter={gutter} data-testid='buy-it-again-list' ref={ref}>
        <InnerContainer listWidth={listWidth} ref={listRef}>
          <ListHeader padding={listPadding}>
            <ListHeaderText tag='h3' size='xsmall' weight='semi'>
              Buy it again
            </ListHeaderText>
            <Link href={accountOrderHistoryPath}>
              <ListHeaderLink
                href={accountOrderHistoryPath}
                onClick={() => handleViewAllClick(accountOrderHistoryPath)}
              >
                View all orders
              </ListHeaderLink>
            </Link>
          </ListHeader>
          <ListContainer maxHeight={showPlaceholder ? null : productListMaxHeight}>
            <GradientStart
              isVisible={!showPlaceholder && !startWatcher?.isIntersecting}
              data-testid='list-gradient-start'
            />
            <ProductList padding={listPadding} showPlaceholder={showPlaceholder}>
              <div ref={startWatcherRef} />
              {children}
              {showPlaceholder && (
                <ListPlaceholder backgroundColor={backgroundColor} data-testid='buy-it-again-list-placeholder'>
                  <ShoppingBags />
                  <p>
                    Your recent purchases will show up here.{'\n'}
                    Easily buy your favorites again and again!
                  </p>
                </ListPlaceholder>
              )}
              <div ref={endWatcherRef} />
            </ProductList>
            <GradientEnd isVisible={!showPlaceholder && !endWatcher?.isIntersecting} data-testid='list-gradient-end' />
          </ListContainer>
        </InnerContainer>
      </OuterContainer>
    );
  }
);
