import React from 'react';
import styled from 'styled-components';

import { focusStyles } from 'src/theme';
import { Link } from 'components/core';
import { Button } from 'shared/components';
import ShoppingBags from 'src/assets/shopping-bags-icon';

import usePaths from 'hooks/use-paths';
import useUI from 'shared/hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

type BuyItAgainViewAllProps = {
  handleViewAllClick: (destinationUrl: string) => void;
};

export function BuyItAgainViewAll({ handleViewAllClick }: BuyItAgainViewAllProps): JSX.Element {
  const UI = useUI();
  const { dispensary } = useDispensary();
  const { href } = usePaths({ dispensary });

  const hasUserPaths = UI.isEmbedded || UI.isStoreFront;
  const accountOrderHistoryPath = hasUserPaths ? `${href}/user/orders` : '/account/orders';

  return (
    <Container>
      <Content>
        <ShoppingBags width={80} height={80} />
        <Link href={accountOrderHistoryPath}>
          <ViewAllButton href={accountOrderHistoryPath} onClick={() => handleViewAllClick(accountOrderHistoryPath)}>
            View all orders
          </ViewAllButton>
        </Link>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .focus-visible {
    ${focusStyles}
  }
`;

const ViewAllButton = styled(Button)`
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 32px;
  margin-top: 20px;
  max-width: 155px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
`;
