import React, { useCallback, useRef } from 'react';
import Truncate from 'react-truncate';

import usePaths from 'hooks/use-paths';

import { getCategoryForProduct } from 'shared/helpers/products';
import { Link } from 'components/core';
import { Typography } from 'src/typography';

import { Price } from 'src/components/product-card/price';
import { Content, Name, ProductImage } from 'src/components/product-card/product-card.components';
import { ProductCardProduct } from 'src/components/product-card/product-card.types';
import { getBrandName } from 'src/components/product-card/product-card.utils';

import {
  Item,
  Brand,
  BrandStrain,
  StyledDetails,
  StyledStrainDetails,
  StyledLink,
  StyledQuickAddButton,
} from './buy-it-again-list-item.styles';

export type BuyItAgainListItemProps = {
  filledQuickAdd?: boolean;
  product: ProductCardProduct;
  productIndex: number;
  onClick: () => void;
  onQuickAddClick: () => void;
};

const dimensions = {
  mobile: 48,
  desktop: 48,
  padding: 8,
  gutter: 16,
};

export const BuyItAgainListItem = ({
  filledQuickAdd = false,
  product,
  onClick,
  onQuickAddClick,
}: BuyItAgainListItemProps): JSX.Element | null => {
  const { href, route } = usePaths({ category: getCategoryForProduct(product), product });
  const brandName = getBrandName(product);
  const cardRef = useRef<HTMLElement | null>(null);

  const handleClick = useCallback((): void => {
    onClick();
  }, [onClick]);

  const handleQuickAddClick = useCallback((): void => {
    onQuickAddClick();
    onClick();
  }, [onQuickAddClick, onClick]);

  return (
    <Item
      dimensions={dimensions}
      aria-label={product.name}
      aria-roledescription='list-item'
      data-testid='buy-it-again-list-item'
      ref={cardRef}
    >
      <Content>
        <Link href={href} route={route}>
          <StyledLink href={href} onClick={handleClick}>
            <ProductImage dimensions={dimensions} width={48} height={48} product={product} />
            <StyledDetails>
              <Price product={product} />
              <Name>
                <Truncate lines={1}>
                  <Typography.Title tag='span' size='small'>
                    {product.name}
                  </Typography.Title>
                </Truncate>
              </Name>
              <BrandStrain>
                {brandName && (
                  <Brand tag='span' size='xsmall'>
                    {brandName}
                  </Brand>
                )}
                {product.strainType && <StyledStrainDetails product={product} />}
              </BrandStrain>
            </StyledDetails>
            <StyledQuickAddButton onClick={handleQuickAddClick} productName={product.name} filled={filledQuickAdd} />
          </StyledLink>
        </Link>
      </Content>
    </Item>
  );
};
