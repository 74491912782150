import styled from 'styled-components';

import { Typography } from 'src/typography';
import { focusStyles } from 'src/theme';
import { Container } from 'src/components/hybrid-carousel/hybrid-carousel-card/hybrid-carousel-card.styles';

export const OuterContainer = styled.div`
  padding: ${({ gutter }) => `10px ${String(gutter)}px 0 0`};

  .focus-visible {
    ${focusStyles}
  }
`;

export const InnerContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.1);
  cursor: default;
  padding: 0;
  width: ${({ listWidth }) => `${String(listWidth)}px`};
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => `${String(padding)}px ${String(padding)}px`};
  width: 100%;
`;

export const ListHeaderText = styled(Typography.Heading)`
  font-size: 18px;
`;

export const ListHeaderLink = styled.a`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 14px;
  font-weight: 600;
  text-transform: ${({ theme }) => theme.customized.textTransforms.links};
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: ${({ maxHeight }) => (maxHeight ? `${String(maxHeight)}px` : `none`)};
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const gradientStyles = (isVisible: boolean): string => `
  height: 56px;
  left: 0;
  opacity: ${isVisible ? 1 : 0};
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 10;
`;

export const GradientStart = styled.div`
  ${({ isVisible }) => gradientStyles(isVisible)}
  background: linear-gradient(0deg, transparent 0%, #ffffff 100%);
  border-radius: 0;
  top: 0;
`;

export const GradientEnd = styled.div`
  ${({ isVisible }) => gradientStyles(isVisible)}
  background: linear-gradient(180deg, transparent 0%, #ffffff 100%);
  border-radius: 0 0 15px 15px;
  bottom: 0;
`;

export const ListPlaceholder = styled.div`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.grey[40]};
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  justify-content: center;
  margin-top: 20px;
  min-height: 175px;
  padding: 20px;

  p {
    line-height: 20px;
    margin: 5px 0 10px;
    max-width: 300px;
    padding: 0 15px;
    text-align: center;
  }
`;

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;
  overflow-y: scroll;
  padding: ${({ padding, showPlaceholder }) => `0 ${String(padding)}px ${showPlaceholder ? String(padding) : 16}px`};
  scrollbar-width: none;
`;
