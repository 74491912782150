import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import type { Product } from 'src/utils/helpers/product';
import { PersonalizedProductsQuery } from 'src/personalization/data-layer/types';
import { SponsoredBrandQuery } from 'src/utils/ads/types';

import { useModals, ModalNames } from 'src/components/modals';
import { useIntersectionObserver } from 'src/hooks/use-intersection-observer';
import { GoogleLogin } from 'src/components/google-login';

import { tracker } from 'src/utils/analytics';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';

import EmptyLoadingState from 'src/components/empty-loading-state';
import { HybridCarouselCard } from 'src/components/hybrid-carousel';
import { usePersonalizedProductsCarousel } from '../use-personalized-products-carousel';

import {
  Content,
  Products,
  Subheading,
  SignUpButton,
  Wrapper,
  Heading,
  AlreadyHaveAccount,
  LoginButton,
  ProductsWrapper,
  ProductsViewport,
  GradientLeft,
  GradientRight,
  ButtonWrapper,
} from './logged-out.styles';

type LoggedOutProps = {
  personalizedProductsQuery: PersonalizedProductsQuery;
  sponsoredBrandQuery?: SponsoredBrandQuery;
};

type TrackUserEventProps = {
  signIn: boolean;
  type: 'email' | 'sso';
};

export const LoggedOut = ({ personalizedProductsQuery, sponsoredBrandQuery }: LoggedOutProps): JSX.Element | null => {
  const startWatcherRef = useRef<HTMLDivElement>(null);
  const startWatcher = useIntersectionObserver(startWatcherRef, { threshold: 1 });
  const endWatcherRef = useRef<HTMLDivElement>(null);
  const endWatcher = useIntersectionObserver(endWatcherRef, { threshold: 0 });

  const flags = useFlags();
  const router = useRouter();
  const { openModal } = useModals();
  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: ProductRecommendationSection.guestRecommendations,
  });

  const personalizationCarouselPlacement = flags['growth.ads.personalization-carousel.placement'];
  const isLoginPlacementsFlagEnabled = flags['growth.ecomm.login-placements.rollout'] ?? false;

  const trackUserEvent = ({ signIn, type }: TrackUserEventProps): void => {
    tracker.gaGTMClickEvent({
      eventCategory: 'account',
      eventAction: signIn ? 'signed in' : 'created account',
      eventLabel: `${type}: {pageType}`,
    });
  };

  const handleOpenSignUpModal = (): void => {
    void openModal(ModalNames.signUp, trackerSource);
    trackUserEvent({ signIn: false, type: 'email' });
  };

  const handleOpenLoginModal = (): void => {
    void openModal(ModalNames.login, trackerSource);
    trackUserEvent({ signIn: true, type: 'email' });
  };

  const handleGoogleLoginSubmit = (): void => {
    trackUserEvent({ signIn: true, type: 'sso' });
  };

  const {
    dimensions,
    handleProductClick,
    handleProductImpression,
    handleQuickAddToCart,
    loading,
    products,
    error,
    theme,
    viewportVisibility,
  } = usePersonalizedProductsCarousel({
    personalizedProductsQuery,
    sponsoredBrandQuery,
    maxProducts: 3,
    maxSponsoredProducts: 1,
  });

  if (error || !products.length) {
    return null;
  }

  const { ref: productsRef } = viewportVisibility;

  return (
    <Wrapper
      {...theme}
      fullWidth
      border
      placement={personalizationCarouselPlacement}
      data-testid='personalized-products-carousel-guest-cta'
    >
      <Content>
        <Heading size='medium' weight='semi' tag='h2'>
          Want personalized recommendations?
        </Heading>
        <Subheading size='large' weight='regular' tag='p'>
          Sign up to get started.
        </Subheading>

        {isLoginPlacementsFlagEnabled ? (
          <ButtonWrapper>
            <GoogleLogin redirectUrl={window.location.href} onSubmit={handleGoogleLoginSubmit} />

            <SignUpButton size='large' width='100%' onClick={handleOpenSignUpModal} {...theme}>
              Continue with email
            </SignUpButton>
          </ButtonWrapper>
        ) : (
          <SignUpButton size='large' onClick={handleOpenSignUpModal} {...theme}>
            Sign Up
          </SignUpButton>
        )}

        <AlreadyHaveAccount size='large'>
          Already have an account? <LoginButton onClick={handleOpenLoginModal}>Log in</LoginButton>
        </AlreadyHaveAccount>
      </Content>

      <EmptyLoadingState page='personalizedCarousel' isLoading={loading} productCount={3} noHeader>
        <Products ref={productsRef}>
          <ProductsViewport>
            <GradientLeft isVisible={!startWatcher?.isIntersecting} />

            <ProductsWrapper>
              <div ref={startWatcherRef} />

              {products.map((product: Product, index) => (
                <HybridCarouselCard
                  carouselTheme={theme}
                  dimensions={dimensions}
                  filledQuickAdd={false}
                  key={product.id}
                  product={product}
                  productIndex={index}
                  onClick={() => handleProductClick(product, index, trackerSource)}
                  onQuickAddClick={() => handleQuickAddToCart(product, index, trackerSource)}
                  onView={() => handleProductImpression(product)}
                />
              ))}

              <div ref={endWatcherRef} />
            </ProductsWrapper>

            <GradientRight isVisible={!endWatcher?.isIntersecting} />
          </ProductsViewport>
        </Products>
      </EmptyLoadingState>
    </Wrapper>
  );
};
