import React from 'react';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useModals, ModalNames } from 'src/components/modals';
import { GoogleLogin } from 'src/components/google-login';
import BlueSparkle from 'src/assets/blue-sparkle-icon';

import { tracker } from 'src/utils/analytics';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';

import {
  Content,
  Subheading,
  SignUpButton,
  Wrapper,
  Heading,
  AlreadyHaveAccount,
  LoginButton,
  ButtonWrapper,
  SignUpContainer,
  ButtonsContainer,
  HeadingsContainer,
  TextContent,
  GoogleLoginWrapper,
  IconContainer,
} from './logged-out-v2.styles';

type TrackUserEventProps = {
  signIn: boolean;
  type: 'email' | 'sso';
};

export function LoggedOutV2(): JSX.Element {
  const flags = useFlags();
  const router = useRouter();
  const { openModal } = useModals();

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: ProductRecommendationSection.guestRecommendations,
  });

  const personalizationCarouselPlacement = flags['growth.ads.personalization-carousel.placement'];
  const isLoginPlacementsFlagEnabled = flags['growth.ecomm.login-placements.rollout'] ?? false;

  const trackUserEvent = ({ signIn, type }: TrackUserEventProps): void => {
    tracker.gaGTMClickEvent({
      eventCategory: 'account',
      eventAction: signIn ? 'signed in' : 'created account',
      eventLabel: `${type}: {pageType}`,
    });
  };

  const handleOpenSignUpModal = (): void => {
    void openModal(ModalNames.signUp, trackerSource);
    trackUserEvent({ signIn: false, type: 'email' });
  };

  const handleOpenLoginModal = (): void => {
    void openModal(ModalNames.login, trackerSource);
    trackUserEvent({ signIn: true, type: 'email' });
  };

  const handleGoogleLoginSubmit = (): void => {
    trackUserEvent({ signIn: true, type: 'sso' });
  };

  return (
    <Wrapper
      backgroundColor='#f5f6f7'
      fullWidth
      border
      placement={personalizationCarouselPlacement}
      data-testid='personalized-products-carousel-logged-out-v2-cta'
    >
      <Content>
        <HeadingsContainer>
          <IconContainer>
            <BlueSparkle />
          </IconContainer>
          <TextContent>
            <Heading size='medium' weight='bold' tag='h2'>
              Sign up for the best experience
            </Heading>
            <Subheading size='medium' weight='regular' tag='p'>
              Get personalized recommendations, checkout faster and reorder your favorites more easily!
            </Subheading>
          </TextContent>
        </HeadingsContainer>
        <SignUpContainer>
          <ButtonsContainer>
            {isLoginPlacementsFlagEnabled ? (
              <ButtonWrapper>
                <GoogleLoginWrapper data-testid='google-login-wrapper'>
                  <GoogleLogin redirectUrl={window.location.href} onSubmit={handleGoogleLoginSubmit} />
                </GoogleLoginWrapper>
                <SignUpButton
                  size='large'
                  width='100%'
                  onClick={handleOpenSignUpModal}
                  googleLoginPlacementEnabled={isLoginPlacementsFlagEnabled}
                >
                  Continue with email
                </SignUpButton>
              </ButtonWrapper>
            ) : (
              <SignUpButton size='large' onClick={handleOpenSignUpModal}>
                Sign Up
              </SignUpButton>
            )}
          </ButtonsContainer>

          <AlreadyHaveAccount size='medium'>
            Already have an account? <LoginButton onClick={handleOpenLoginModal}>Log in</LoginButton>
          </AlreadyHaveAccount>
        </SignUpContainer>
      </Content>
    </Wrapper>
  );
}
