import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { PersonalizedProductsQuery, RecommendedProductsQuery } from 'src/personalization/data-layer/types';
import { SponsoredBrandQuery } from 'src/utils/ads/types';

import type { Product } from 'src/utils/helpers/product';

import { HybridCarousel, HybridCarouselCard } from 'src/components/hybrid-carousel';
import { usePersonalizedProductsCarousel } from '../../use-personalized-products-carousel';
import { useBuyItAgainProducts } from '../../use-buy-it-again-products';
import { BuyItAgain } from './buy-it-again';

type BlendedPersonalizedProductCarouselProps = {
  heading?: string;
  icon?: React.ReactNode;
  personalizedProductsQuery: PersonalizedProductsQuery;
  recommendedProductsQuery: RecommendedProductsQuery;
  sponsoredBrandQuery?: SponsoredBrandQuery;
  subheading?: string;
};

export const BlendedPersonalizedProductCarousel = ({
  heading,
  icon,
  personalizedProductsQuery,
  recommendedProductsQuery,
  sponsoredBrandQuery,
  subheading,
}: BlendedPersonalizedProductCarouselProps): JSX.Element | null => {
  const flags = useFlags();

  const personalizationCarouselPlacement = flags['growth.ads.personalization-carousel.placement'];
  const buyItAgainProductsEnabled = flags['growth.ads.buy-it-again-carousel.rollout'];
  const margin = personalizationCarouselPlacement === 'secondary' ? 10 : 40;

  const {
    dimensions,
    handleProductClick,
    handleProductImpression,
    handleQuickAddToCart,
    products,
    theme,
    error,
    viewportVisibility,
  } = usePersonalizedProductsCarousel({ personalizedProductsQuery, sponsoredBrandQuery, maxSponsoredProducts: 3 });

  const buyItAgainProducts = useBuyItAgainProducts({
    recommendedProductsQuery,
  });
  const { loading: buyItAgainLoading, error: buyItAgainError } = buyItAgainProducts;

  if (buyItAgainError) {
    console.error("Error fetching user's previously purchased products.", buyItAgainError);
  }

  const isLoading = products.length > 0 && buyItAgainLoading;

  if (error || !products.length) {
    return null;
  }

  return (
    <div data-testid='personalized-products-carousel'>
      <HybridCarousel
        border
        carouselTheme={theme}
        customLoadingSkeleton='personalizedCarousel'
        dimensions={dimensions}
        fullWidth
        heading={heading}
        icon={icon}
        loading={isLoading}
        margin={margin}
        subheading={subheading}
        viewportVisibility={viewportVisibility}
      >
        {buyItAgainProductsEnabled && <BuyItAgain dimensions={dimensions} theme={theme} {...buyItAgainProducts} />}
        {products.map((product: Product, index) => (
          <HybridCarouselCard
            carouselTheme={theme}
            dimensions={dimensions}
            key={product.id}
            product={product}
            productIndex={index}
            onView={() => handleProductImpression(product)}
            onClick={() => handleProductClick(product, index)}
            onQuickAddClick={() => handleQuickAddToCart(product, index)}
          />
        ))}
      </HybridCarousel>
    </div>
  );
};
