import { ApolloError } from 'apollo-client';

import { SponsoredBrandQuery } from 'src/utils/ads/types';
import { ViewportVisibility } from 'src/hooks/use-viewport-visibility';
import { Product } from 'src/utils/helpers/product';
import { useSponsoredBrandData, useSponsoredBrandHandlers } from 'src/utils/ads/hooks/sponsored-brand';

type UseSponsoredBrandProductsParams = {
  sponsoredBrandQuery?: SponsoredBrandQuery;
  viewportVisibility: ViewportVisibility;
};

export type UseSponsoredBrandProductsReturn = {
  error?: ApolloError;
  loading: boolean;
  products: Product[];
  handleProductClick?: (product: Product, index: number) => void;
  handleProductImpression?: (product: Product) => void;
  handleQuickAddToCart?: (product: Product, index: number) => void;
  handleViewAllClick?: () => void;
};

export function useSponsoredBrandProducts({
  sponsoredBrandQuery,
  viewportVisibility,
}: UseSponsoredBrandProductsParams): UseSponsoredBrandProductsReturn {
  const { data, loading, error } = sponsoredBrandQuery ?? {};
  const { brandName, campaignId, globalTrackers, products } = useSponsoredBrandData(data);
  const { hasBeenVisible } = viewportVisibility;

  const {
    handleProductClick,
    handleProductImpression,
    handleViewAllClick,
    handleQuickAddToCart,
  } = useSponsoredBrandHandlers({
    brandName,
    campaignId,
    globalTrackers,
    products,
    hasBeenVisible,
    placement: 'homepage-personalized-products-carousel',
  });

  if (!sponsoredBrandQuery || loading === undefined) {
    return {
      loading: false,
      products: [],
    };
  }

  return {
    products,
    loading,
    error,
    handleProductClick,
    handleQuickAddToCart,
    handleViewAllClick,
    handleProductImpression,
  };
}
